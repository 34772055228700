<template>
  <div class="app-detail">AppDetail</div>
</template>

<script>
export default {
  name: 'AppDetail',
};
</script>

<style lang="less" scoped></style>
